
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISurfaceAction, ITooth, IToothDamage, IToothSurface } from '../types';

@Component
export default class ToothSurface extends Vue {
  @Prop({ default: () => { } })
  readonly value: ITooth | undefined;

  @Prop({ default: () => false })
  readonly small: boolean | string | undefined;

  @Prop({ default: () => false })
  readonly large: boolean | string | undefined;

  @Prop({ default: () => false })
  readonly menu: boolean | string | undefined;

  @Prop({ default: () => false })
  readonly top: boolean | string | undefined;

  @Prop({ default: () => false })
  readonly bottom: boolean | string | undefined;

  @Prop({ default: () => false })
  readonly left: boolean | string | undefined;

  @Prop({ default: () => false })
  readonly right: boolean | string | undefined;

  @Prop({ default: () => false })
  readonly disabled: boolean | string | undefined;

  @Prop({ default: () => { } })
  readonly damage: IToothDamage | undefined;

  showMenu = false;

  get toothStyle(): string {
    let stl = 'display: table-cell;';
    stl += ' width: ' + this.size + 'px;';
    stl += ' height: ' + this.size + 'px;';
    return stl;
  }

  get size(): number {
    return this.large || this.large === ''
      ? 300
      : (this.small || this.small === '' ? 30 : 50);
  }

  get toothClass(): string {
    let cls = 'tooth-surface';
    if (this.showMenu && this.menu) {
      cls += ' selected';
    }
    if (this.disabled || this.disabled === '') {
      cls += ' surface-disabled';
    }
    return cls;
  }

  get smallSurface(): boolean {
    return this.value !== undefined && this.value !== null && this.value.order > 0 && this.value.order < 5
  }

  get exists(): boolean {
    return this.value !== undefined && this.value !== null && this.value.type !== 'X';
  }

  get damaged(): boolean {
    return this.value !== undefined && this.value !== null && (
      this.value.surfaces.M.damage ||
      this.value.surfaces.D.damage ||
      this.value.surfaces.V.damage ||
      this.value.surfaces.L.damage ||
      this.value.surfaces.O.damage
    );
  }

  action(surface: IToothSurface) {
    const payload: ISurfaceAction = {
      tooth: this.value!,
      surface
    }
    this.$emit('action', payload);
  }

  close() {
    this.showMenu = false;
    this.$emit('close');
  }

  segmentStyle(surface: IToothSurface): any {
    let strokeWidth = '0';

    if (this.smallSurface) {
      strokeWidth = this.value && this.value.type === 'X' ? '2' : '10'
    } else {
      strokeWidth = this.value && this.value.type === 'X' ? '2' : '45'
    }

    return {
      fill: this.segmentFillColor(surface),
      'fill-rule': 'nonzero',
      stroke: this.segmentStrokeColor(surface),
      'stroke-width': strokeWidth
    };
  }

  filter(surface: IToothSurface): IToothSurface {
    if (this.damage) {
      return {
        type: surface.type,
        cure: 'N',
        damage: this.damage.surfaces.some(i => i === surface.type),
        processing: false
      }
    } else {
      return surface;
    }
  }

  segmentFillColor(surface: IToothSurface): string {
    surface = this.filter(surface);

    if (surface.cure === 'O') {
      if (surface.damage) {
        return '#B3B3B3';
      } else {
        return '#B3B3B3';
      }
    } else if (surface.cure === 'I') {
      if (surface.damage) {
        return '#96C2F3';
      } else {
        return '#96C2F3';
      }
    } else {
      if (surface.damage) {
        return '#FF6680';
      } else {
        return '#FFFFFF';
      }
    }
  }

  segmentStrokeColor(surface: IToothSurface): string {
    surface = this.filter(surface);

    if (surface.damage) {
      return '#B70000';
    } else {
      return this.value && this.value.type === 'X' ? '#F6F6F6' : '#D6D6D6';
    }
  }
}
